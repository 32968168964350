import React, { useEffect, useState, useRef } from 'react';
import Axios from '../../config/axios';
import { Drawer, Card, message } from 'antd';
import { getGroupedReports, downloadReportByName } from '../../services/ReportsService';
import { Fragment } from 'react';
import { toast, confirm } from '@rickylandino/react-messages';
import SelectShowModal from '../Helpers/SelectShowModal';

function ReportsSlider(props) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const listRef = useRef();

    const [state, setState] = useState({
        showReportCriteriaModal: false,
        showSelectShowModal: false,
        selectedReport: ''
    });

    const [modalInfo, setModalInfo] = useState({
        show: false
    });

    const [height, setHeight] = useState({
        triggered: false,
        value: ''
    });

    const [reportsToDisplay, setReportsToDisplay] = useState({});
    const [biggestGroup, setBiggestGroup] = useState(null);

    useEffect(() => {
        getGroupedReports(userInfo.userId, userInfo.jobShopDivision).then(data => {
            console.log(data);
            if (data) {
                setReportsToDisplay(data);

                if (Object.keys(data).length == 0) {
                    setHeight({
                        ...height,
                        triggered: true
                    });
                }

                var size = 0;
                var bg = null;
                for (const property in data) {
                    if (data[property].length > size) {
                        size = data[property].length;
                        bg = property;
                    }
                }

                setBiggestGroup(bg);
            } else {
                toast.error("Something went wrong");
            }
        });
    }, []);

    useEffect(() => {

        setTimeout(() => getListSize(), 500);

        window.addEventListener("resize", getListSize);
    }, [listRef]);

    useEffect(() => {
        if (props.showModal) {
            setModalInfo({
                show: props.showModal
            });
        }
    }, [props.showModal]);

    const getListSize = () => {
        const newHeight = listRef?.current?.clientHeight;
        if (newHeight) {
            setHeight({
                triggered: true,
                value: newHeight
            });
        }
    };

    function handleClose() {
        setModalInfo({
            show: false
        });

        setState({
            ...state,
            showReportCriteriaModal: false
        });

        setTimeout(() => { props.hideModal(); }, 1000);
    }

    function hideSelectShowModal() {
        setTimeout(() => {
            setState({
                ...state,
                showSelectShowModal: false
            });
        }, 1000);
    }

    function handleReportRequest(e) {
        e.preventDefault();

        setState({
            ...state,
            selectedReport: e.target.id,
            showSelectShowModal: true,
        });

        //switch (e.target.id) {
        //    case 'Client Lists (Plan Details)':
        //        setState({
        //            ...state,
        //            showReportCustomDataModal: {
        //                show: false,
        //                reportName: '',
        //                reportType: ''
        //            },
        //            showReportCriteriaModal: true,
        //        });
        //        break;
        //    case 'Generate Follow-up Statements':
        //        setState({
        //            ...state,
        //            showReportCriteriaModal: false,
        //            showReportCustomDataModal: {
        //                show: true,
        //                reportName: 'Generate Follow-Up Statements Report',
        //                reportType: 'displayDateRangeModal'
        //            }
        //        });
        //        break;
        //    case 'Batch Quarterly Billing':
        //        setState({
        //            ...state,
        //            showReportCriteriaModal: false,
        //            showReportCustomDataModal: {
        //                show: true,
        //                reportName: 'Batch Quarterly Billing Report',
        //                reportType: 'displayYearRangeModal',
        //            }
        //        });
        //        break;
        //    default:
        //        downloadReport(e.target.id);
        //        break;
        //}
    }

    function downloadReport(reportName, selectedShow) {
        //toast.loading("Report generation in progress...", { key: 'loading', alignment: 'bottom-left' });

        const key = "downloademailtemplate";
        message.loading({
            content: 'Report generation in progress...',
            key,
            duration: 0,
            style: {
                position: "fixed",
                bottom: 0,
                left: 0
            },
        });

        let postdata = {
            reportName,
            showcode: selectedShow.showcode,
            showName: selectedShow.name,
            showDate: selectedShow.startdate
        }

        console.log(postdata);

        downloadReportByName(postdata).then(data => {
            if (data) {
                const url = window.URL.createObjectURL(new Blob([data]));
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = reportName + ".xlsx";
                a.click();
                window.URL.revokeObjectURL(url);

                message.destroy(key);
            } else {
                toast.warning("Report is not yet defined");
            }
        });
    }

    function setSelectedShowCode(selectedShow) {
        hideSelectShowModal();

        downloadReport(state.selectedReport, selectedShow);
    }

    return (
        <Drawer
            closeIcon={<i className="fas fa-chevron-left float-left"></i>}
            title='Report/Output'
            placement={'right'}
            onClose={handleClose}
            visible={modalInfo.show}
            key='reportsSlider'
            width={'85%'}
            className="d-flex"
            footer={
                <div className="d-flex justify-content-between">
                    <div>
                        <button className="btn btn-outline-primary float-right" onClick={handleClose}>Close</button>
                    </div>
                </div>
            }
        >
            <div className="row align-items-start mt-3 ml-3 h-100">
                {Object.keys(reportsToDisplay).length > 0 ?
                    <Fragment>
                        {Object.keys(reportsToDisplay).map((key, idx) => {
                            return (
                                <div className="col col-lg-3 col-12" style={{ height: height.value + 'px' }} key={idx} ref={biggestGroup === key ? listRef : null} id="getHeight">
                                    <Card title={`${key} Reports`} className="d2p-reporting-card">
                                        {reportsToDisplay[key].map((r, idx2) => <p className="a u" key={idx2} value={r.reportId} id={r.reportName} onClick={handleReportRequest}>{r.reportName}</p>)}

                                    </Card>
                                </div>
                            );
                        })}
                    </Fragment>
                    :
                    <div>
                        <h2 className="color-primary">Your Profile Is Not Configured For Access To Reporting/Output</h2>
                        <h4>Contact Your System Administrator To Request Access</h4>
                    </div>
                }

                {!height.triggered &&
                    <div className="fixed-white-cover" />
                }
            </div>

            {/*<div className="row align-items-center h-100">*/}
            {/*    {!height.triggered &&*/}
            {/*        <div className="fixed-white-cover" />*/}
            {/*    }*/}

            {/*    {height.triggered &&*/}
            {/*        <div className="col col-lg-3 col-12" style={{ height: height.value + 'px' }}>*/}
            {/*            <Card title="Show Reports" className="d2p-reporting-card">*/}
            {/*                <p className="a u">Code & Booth</p>*/}
            {/*                <p className="a u" id="rptPlanDeliverables" onClick={handleReportRequest}>Booth Furnishings</p>*/}
            {/*                <p className="a u">PreReg List Exhibitor Selections</p>*/}
            {/*                <p className="a u">Total Package Exhibitors List</p>*/}
            {/*                <p className="a u">PreReg List No Show Counts</p>*/}
            {/*                <p className="a u">Deposits Due</p>*/}
            {/*                <p className="a u" id="rptBoothSales" onClick={handleReportRequest}>Booth Sales</p>*/}
            {/*                <p className="a u">Floor Plan Assignment</p>*/}
            {/*                <p className="a u">Till Report</p>*/}
            {/*                <p className="a u">Waitlist Report</p>*/}
            {/*            </Card>*/}
            {/*        </div>*/}
            {/*    }*/}
            {/*    {height.triggered &&*/}
            {/*        <div className="col col-lg-3 col-12" style={{ height: height.value + 'px' }}>*/}
            {/*            <Card title="A/R Reports" className="d2p-reporting-card">*/}
            {/*                <p className="a u">AR Type Summary Report by Show</p>*/}
            {/*                <p className="a u">AR Type Summary Report by Date</p>*/}
            {/*                <p className="a u">Specific Exhibitor AR Details</p>*/}
            {/*                <p className="a u">AR Daily Report</p>*/}
            {/*                <p className="a u">AR By Show</p>*/}
            {/*                <p className="a u">Sales Journal</p>*/}
            {/*                <p className="a u">AR Cash Journal</p>*/}
            {/*                <p className="a u">Credit Memo</p>*/}
            {/*                <p className="a u" id="rptDebitMemo" onClick={handleReportRequest}>Debit Memo</p>*/}
            {/*            </Card>*/}
            {/*        </div>*/}
            {/*    }*/}
            {/*    <div className="col col-lg-3 col-12" ref={listRef} id="getHeight">*/}
            {/*        <Card title="Show Program" className="d2p-reporting-card">*/}
            {/*            <p className="a u">Alpha Listing</p>*/}
            {/*            <p className="a u">Advertiser Listing</p>*/}
            {/*            <p className="a u">Booth Order Listing (All Exhibitors)</p>*/}
            {/*            <p className="a u">Booth Order Listing (Slip Sheet)</p>*/}
            {/*            <p className="a u">Service Category Listing</p>*/}
            {/*            <hr />*/}
            {/*            <p className="a u">Pre Booth - Alpha Listing</p>*/}
            {/*            <p className="a u">Pre Booth - Advertiser Listing</p>*/}
            {/*            <p className="a u">Pre Booth - Booth Order Listing (All Exhibitors)</p>*/}
            {/*            <p className="a u">Pre Booth - Booth Order Listing (Slip Sheet)</p>*/}
            {/*            <p className="a u">Pre Booth - Service Category Listing</p>*/}
            {/*        </Card>*/}
            {/*    </div>*/}
            {/*    {height.triggered &&*/}
            {/*        <div className="col col-lg-3 col-12" style={{ height: height.value + 'px' }}>*/}
            {/*            <Card title="Past Show Registration Reports" className="d2p-reporting-card">*/}
            {/*                <p className="a u">Pre Show Counts Report</p>*/}
            {/*                <p className="a u">Post Show Counts Report</p>*/}
            {/*                <p className="a u">Visitor Count Record</p>*/}
            {/*                <p className="a u">Matchback Final Counts Report</p>*/}
            {/*                <p className="a u">Universe Counts Report</p>*/}
            {/*                <p className="a u">Attendees Company Alpha Report</p>*/}
            {/*            </Card>*/}
            {/*        </div>*/}
            {/*    }*/}
            {/*</div>*/}

            {state.showSelectShowModal &&
                <SelectShowModal showModal={state.showSelectShowModal} hideSelectShowModal={hideSelectShowModal} setSelectedShowCode={setSelectedShowCode} />
                //<SelectShowAndPriorShowsModal showModal={state.showSelectShowModal} hideSelectShowModal={hideSelectShowModal} setSelectedShowCode={setSelectedShowCode} modalTitle='Call Sheet Generator' />
            }
        </Drawer>
    );
}

export default ReportsSlider;