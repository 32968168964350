import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import Moment from 'moment';
import { dateWithNoTimezone } from './DateFormat';
import { useForm, Controller } from "react-hook-form";
import { Modal, Empty, Spin, Select, Alert, Table, Space } from 'antd';
import ReactLoading from 'react-loading';
import { Fragment } from 'react';

export default function SelectShowModal(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const { Option } = Select;

    const [state, setState] = useState({
        showModal: props.showModal,
        shows: [],
        loading: true
    });

    const [selectedShow, setSelectedShow] = useState(null);

    useEffect(() => {
        setState({
            ...state,
            loading: true
        });

        let postdata = {};
        Axios.post(`/api/GetActiveShowList`, postdata
        ).then(response => {
            let shows = response.data;

            setState({
                ...state,
                shows,
                showModal: true,
                loading: false
            });
        }).catch(error => {
            console.log(error);
        });
    }, [props]);

    function closeModal() {
        //This prevents the modal from opening in the parent unless called upon again
        props.hideSelectShowModal();

        setState({
            ...state,
            showModal: false
        });
    }

    function openModal() {
        setState({
            ...state,
            showModal: true
        });
    }

    const columns = [
        {
            dataIndex: 'showcode',
            key: 'showcode',
            width: 50,
        },
        {
            title: 'Show',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: "Show Dates",
            dataIndex: "showdates",
            render: (text, record) => (
                <span>{Moment(dateWithNoTimezone(record.startdate)).format("L")} - {Moment(dateWithNoTimezone(record.finishdate)).format("L")}</span>
            )
        }
    ];

    function onSelectShow() {
        console.log(selectedShow);

        props.setSelectedShowCode(selectedShow);
    }

    function handlePastShowsChange() {
        //console.log(selectedShow);

    }

    return (
        <>
            <Modal
                visible={state.showModal}
                title={state.modalTitle}
                onCancel={closeModal}
                footer={[
                    <button className="btn btn-submit" onClick={onSelectShow}>
                        Select Show
                    </button>,
                    <span>&nbsp;&nbsp;</span>,
                    <button className="btn btn-outline-primary" onClick={closeModal}>
                        Close
                    </button>
                ]}
            >
                <h4>Select D2P Show</h4>
                {state.loading ?
                    <ReactLoading className='tableLoading' type={"spokes"} color={'Navy'} width={30}
                        style={{ display: 'flex', alignItems: 'center', height: '100%', margin: '0 auto', width: '30px', fill: '#6FB246' }}
                    />
                    :
                    <Fragment>
                        {
                            state.shows.length > 0 ?
                                <>
                                    <Table
                                        width={'50vw'}
                                        rowKey='showcode'
                                        dataSource={state.shows}
                                        columns={columns}
                                        size='small'
                                        pagination={false}
                                        rowClassName={(record, index) => record.showcode === selectedShow?.showcode ? 'row-selected' : ''}
                                        scroll={{
                                            y: '30vh',
                                        }}
                                        onRow={(record, rowIndex) => {
                                            return {
                                                onClick: event => {
                                                    if (event.target.id === 'delete') {
                                                        //removeTag(rowIndex);
                                                    } else {
                                                        setSelectedShow(record); setValue('formFields', record);
                                                    }

                                                },
                                                onDoubleClick: event => {
                                                    setSelectedShow(record);
                                                    onSelectShow();
                                                }
                                            };
                                        }} />

                                    <Select mode="multiple" className="form-control-custom d-inline-block" onChange={handlePastShowsChange} style={{ width: '100%' }} placeholder="Please select one or more past shows" >
                                        {state.shows.map((show, idx) => <option key={idx} value={show.showcode}>{show.name}</option>)}
                                    </Select>
                                </>
                                :
                                <Empty description={<p>No Shows Found</p>} />
                        }
                    </Fragment>
                }
                
            </Modal>
        </>
    );
}