import React, { useEffect, useState } from 'react';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { useNavigate, useLocation } from "react-router-dom";
import { Empty, Spin, Alert, Table } from 'antd';

export default function WidgetRegistrationCounts(props) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [state, setState] = useState({
        shows: [],
        dataLoaded: false
    });

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);

    useEffect(() => {
        setState({
            ...state,
            dataLoaded: false
        });

        LoadData();
    }, [props]);

    function LoadData() {
        let postdata = {};

        Axios.post(`/api/GetRegistrationCountsByShow`, postdata
        ).then(response => {
            console.log(response.data);

            let shows = response.data;
            setState({
                ...state,
                shows,
                dataLoaded: true
            });
        }).catch(error => {
            console.log(error);
        });
    }

    const columns = 
        [
            { dataIndex: 'showcode', title: 'Show Code', key: 'showcode', align: 'center', hidden: true },
            { dataIndex: 'showabbreviation', title: 'Show', key: 'showabbreviation', align: 'center' },
            { dataIndex: 'prereg', title: 'Pre-Reg', key: 'prereg', align: 'center' },
            { dataIndex: 'pending', title: "Pending", key: 'pending', align: 'center' },
            { dataIndex: 'total', title: "Total", key: 'total', align: 'center' },
            { dataIndex: 'diff', title: "Diff", key: 'diff', align: 'center' },
            { dataIndex: 'priorshowabbreviation', title: "Prior", key: 'priorshowabbreviation', align: 'center' },
            { dataIndex: 'priorprereg', title: 'Pre-Reg', key: 'priorprereg', align: 'center' },
            { dataIndex: 'priorattendees', title: "Attendees", key: 'priorattendees', align: 'center' },
            { dataIndex: 'priorcode7', title: "Code 7", key: 'priorcode7', align: 'center' }
        ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        /*console.log('selectedRowKeys changed: ', newSelectedRowKeys);*/
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    return (
        <div className="row">
            <div className="col-lg-12">
                <Spin spinning={!state.dataLoaded}>
                    <div style={!state.dataLoaded ? {} : { display: 'none' }}>
                        <Alert
                            message="Loading Registration Counts"
                            description="Please stand by while we retrieve registration counts"
                            type="info"
                        />
                    </div>
                    {state.dataLoaded &&
                        <div className="row">
                            <div className="col-sm-10 col-md-10 col-lg-10">
                                {state.shows.length === 0 ?
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                        <span>
                                            No Show Information Found
                                        </span>
                                    } />
                                    :
                                    <div className="fullTable form-group col-lg-12">
                                        <Table className="custom-ant-selection"
                                            rowKey={item => item.showcode}
                                            rowSelection={rowSelection}
                                            hideSelectionColumn={true}
                                            bordered
                                            dataSource={state.shows}
                                            columns={columns}
                                            pagination={{
                                                defaultPageSize: 25,
                                                showSizeChanger: true,
                                                pageSizeOptions: ['10', '25', '50', '100'],
                                                showTotal: (total, range) => (
                                                    <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                        Showing {range[0]}-{range[1]} of {total}
                                                    </span>
                                                )
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: () => {
                                                        let selRows = [record.showcode];
                                                        setSelectedRowKeys([...selRows]);
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </Spin>
            </div>
        </div>
    );
}