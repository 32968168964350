import React, { useEffect, useState, useRef } from 'react';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { useNavigate, useLocation } from "react-router-dom";
import Moment from 'moment';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { Empty, Spin, Alert, Table, Input } from 'antd';
import ShowDetailSliderVO from './ShowDetailSliderVO';

export default function ShowDetailInfo(props) {
    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState({
        selectedCustomerId: props.selectedCustomerId,
        dataLoaded: false,
        selectedCustomer: {},
        shows: [],
        filteredShows: [],
        selectedShowCode: null,
        selectedShowName: '',
        totalYesShows: '',
        totalCancelShows: '',
        totalNoShows: '',
        totalOtherShows: '',
        totalShows: '',
        firstShow: '',
        showShowDetailSliderVO: false,
        leadsList: [],
        searchValue: ''
    });

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);

    const [shows, setShows] = useState([]);
    const [filteredShows, setfilteredShows] = useState([]);

    useEffect(() => {
        
    }, []);

    useEffect(() => {
        getCustomerShows(props.selectedCustomerId);

        console.log('here in useEffect');

    }, [props]);

    function getCustomerShows(id) {
        let postdata = {};
        postdata.company_id = id;
        Axios.post(`/api/GetCustomerShows`, postdata
        ).then(response => {
            const shows = response.data;

            if (state.selectedshow === "" & shows.length > 0) {
                var selectedshow = state.shows[0];

                setState({ ...state, selectedshow });
            }

            var totalYesShows = 0;
            var totalCancelShows = 0;
            var totalNoShows = 0;
            var totalOtherShows = 0;
            var totalShows = 0;
            var firstShow = "";

            for (let i = 0; i < shows.length; i++) {
                // loop through your data
                if (shows[i].salesstatus === 'Yes') {
                    totalYesShows += 1;
                }
                else if (shows[i].salesstatus === 'Cancel') {
                    totalCancelShows += 1;
                }
                else if (shows[i].salesstatus === 'No') {
                    totalNoShows += 1;
                }
                else {
                    totalOtherShows += 1;
                }
                totalShows += 1;

                if (i === (shows.length - 1)) {
                    var mystartdate = new Date(shows[i].startdate);
                    var myCurrentYear = new Date().getFullYear();

                    firstShow = mystartdate.getFullYear() + ' (' + (myCurrentYear - mystartdate.getFullYear()) + ' years)';
                    //console.log(shows[i].name);
                }
            }

            setShows(shows);
            setfilteredShows(shows);

            setState({
                ...state,
                totalYesShows: totalYesShows,
                totalCancelShows: totalCancelShows,
                totalNoShows: totalNoShows,
                totalOtherShows: totalOtherShows,
                totalShows: totalShows,
                firstShow: firstShow,
                shows,
                dataLoaded: true,
                showShowDetailSliderVO: false
            });

        }).catch(error => {
            console.log(error);
        });

        //console.log(this.state.shows);
    }

    function renderBooths(record) {
        let myBooths = "";

        if (record.booth1 !== null) {
            myBooths += record.booth1 + ", ";
        }
        if (record.booth2 !== null) {
            myBooths += record.booth2 + ", ";
        }
        if (record.booth3 !== null) {
            myBooths += record.booth3 + ", ";
        }
        if (record.booth4 !== null) {
            myBooths += record.booth4 + ", ";
        }

        if (myBooths !== "") {
            myBooths = myBooths.slice(0, -2);
        }

        return myBooths;
    }
        
     const columns = [
        {
            dataIndex: 'showcode',
            title: 'showcode',
            key: 'showcode',
            hidden: true
        }, {
            dataIndex: 'name',
            title: 'Show',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name)
        }, {
            dataIndex: 'salesstatus',
            title: 'Status',
            key: 'salesstatus',
            sorter: (a, b) => {
                if (a && a.salesstatus && a.salesstatus.length && b && b.salesstatus && b.salesstatus.length) {
                    return a.salesstatus.length - b.salesstatus.length;
                } else if (a && a.salesstatus && a.salesstatus.length) {
                     // That means be has null salesstatus, so a will come first.
                     return -1;
                } else if (b && b.salesstatus && b.salesstatus.length) {
                     // That means a has null salesstatus so b will come first.
                     return 1;
                 }

                 // Both salesstatus has null value so there will be no order change.
                 return 0;
             },
             align: 'center'
        }, {
            dataIndex: 'startdate',
            title: 'Show Date',
            key: 'startdate',
            width: 120,
            sorter: (a, b) => new Date(a.startdate) - new Date(b.startdate),
            render: (startdate) => {
                return (
                    <div>
                        {startdate === null ? '' : Moment(dateWithNoTimezone(startdate)).format("L")}
                    </div>
                );
            }
        }, {
            title: 'Booth(s)',
            key: 'booth1',
            render: (record) => (
                renderBooths(record)),
            sorter: (a, b) => {
                if (a && a.booth1 && a.booth1.length && b && b.booth1 && b.booth1.length) {
                    return a.booth1.length - b.booth1.length;
                } else if (a && a.booth1 && a.booth1.length) {
                     // That means be has null booth1, so a will come first.
                     return -1;
                } else if (b && b.booth1 && b.booth1.length) {
                     // That means a has null booth1 so b will come first.
                     return 1;
                 }

                 // Both booth1 has null value so there will be no order change.
                 return 0;
             },
             align: 'center'
        }, {
            dataIndex: 'prereglist',
            title: 'PreReg List',
            key: 'prereglist',
            render: (prereglist) => (
                prereglist !== null && (prereglist === 'Yes' || prereglist === 'True' || prereglist === 1) ? <span>&#10003;</span> : <span>&nbsp;</span>
            ),
            align: 'center'
        }, {
            dataIndex: 'weblink',
            title: 'Web Link',
            key: 'weblink',
            render: (weblink) => (
                weblink !== null && (weblink === 'Yes' || weblink === 'True' || weblink === 1) ? <span>&#10003;</span> : <span>&nbsp;</span>
            ),
            align: 'center'
        }, {
            dataIndex: 'leadretrievalstatus',
            title: 'Lead Retrieval',
            key: 'leadretrievalstatus',
            render: (leadretrievalstatus) => (
                leadretrievalstatus !== null && (leadretrievalstatus === 'Yes' || leadretrievalstatus === 'True' || leadretrievalstatus === 1) ? <span>&#10003;</span> : <span>&nbsp;</span>
            ),
            align: 'center'
        }, {
            dataIndex: 'caravanfreight',
            title: 'Caravan Freight',
            key: 'caravanfreight',
            render: (leadretrievalstatus) => (
                leadretrievalstatus !== null && (leadretrievalstatus === 'Yes' || leadretrievalstatus === 'True' || leadretrievalstatus === 1) ? <span>&#10003;</span> : <span>&nbsp;</span>
            ),
            align: 'center'
        }, {
            title: 'Show Details',
            key: 'action',
            width: 120,
            render: (record) => (
                <span className="hover" onClick={(e) => loadShowDetails(e, record)}><i id="showDetails" className="fas fa-edit fa-1x ml-3 text-center hover"></i></span>
            ),
            align: 'center'
        }, {
            title: 'AR Details',
            key: 'action',
            width: 120,
            render: (record) => (
                <i id="arDetails" className="fas fa-dollar-sign fa-1x ml-3 text-center hover" onClick={(e) => loadARDetails(e, record)}></i>
            ),
            align: 'center'
        }, {
            dataIndex: 'leadscollected',
            title: 'Leads Collected',
            key: 'leadscollected',
            render: (leadscollected, record) => (
                leadscollected > 0 ? <span className="hover" onClick={(e) => handleExportLeads(e, record)}>< i id="leadsDownload" className="fas fa-download fa-1x ml-3 text-center hover"></i > { leadscollected.toString() } leads</span > : <span />
            ),
            align: 'center'
        }
    ].filter(item => !item.hidden);

    function handleExportLeads(e, record) {
        console.log(record.showcode);
        console.log(record.name);

        var myLeadsDay;
        var myFileName;

        myLeadsDay = "all";
        myFileName = "D2PLeads_" + record.name.replace(' ', '_') + "_both_days";

        let postdata = {
            exhibitorid: props.selectedCustomerId,
            showcode: parseInt(record.showcode),
            showName: record.name,
            whichday: myLeadsDay
        }

        Axios.post(`/api/DownloadCollectedLeads_XLSX`, postdata, {
            responseType: 'blob'
        }).then(response => {
            console.log(response);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = myFileName + ".xlsx";
            a.click();
            window.URL.revokeObjectURL(url);
        }).catch(error => {
            console.log(error);
        });
    }

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function loadShowDetails(e, record) {
        setState({
            ...state,
            selectedCustomerId: state.selectedCustomerId,
            selectedShowCode: record.showcode,
            selectedShowName: record.name,
            showShowDetailSliderVO: true
        });
    }

    function loadARDetails(e, record) {
        toast.info("AR Details View Coming Soon!");

        setState({
            ...state,
            showShowDetailSliderVO: false
        });
    }

    function searchShows(value) {
        let searchMatches = [];
        var currShow;
        var valueCleared = false;
        for (let i = 0; i < shows.length; i++) {
            currShow = shows[i];

            if (value !== '') {
                if ((currShow.name !== null && currShow.name.toLowerCase().includes(value.toLowerCase())) ||
                    (currShow.salesstatus !== null && currShow.salesstatus.toLowerCase().includes(value.toLowerCase())) ||
                    (currShow.booth1 !== null && currShow.booth1.toLowerCase().includes(value.toLowerCase())) ||
                    (currShow.startdate !== null && Moment(dateWithNoTimezone(currShow.startdate)).format("L") === value)) {

                    searchMatches = [...searchMatches, shows[i]];
                }
            }
            else {
                valueCleared = true;
            }
        }

        setfilteredShows(valueCleared ? [...shows] : [...searchMatches]);
    }

    function searchValueChanged(e) {
        setState({
            ...state,
            searchValue: e.target.value,
            showShowDetailSliderVO: false
        });
    }

    return (
        <div className="row">
            <div className="col-lg-12">
                <Spin spinning={!state.dataLoaded}>
                    <div style={!state.dataLoaded ? {} : { display: 'none' }}>
                        <Alert
                            message="Loading Show Details"
                            description="Please stand by while we retrieve show information for this customer"
                            type="info"
                        />
                    </div>
                    {state.dataLoaded &&
                        <div className="row">
                            <h5 className="frame-heading mt-3">
                                -- SHOW DETAIL INFO --
                            </h5>
                            <div className="col-sm-11 col-md-11 col-lg-12">
                                <Input.Search
                                    style={{ paddingTop: "20px", margin: "0 0 10px 0", width: "50%" }}
                                    placeholder="Search shows by show name, date, sales status or booth # ..."
                                    enterButton
                                    onSearch={searchShows}
                                    allowClear
                                    value={state.searchValue}
                                    onChange={searchValueChanged}
                                />
                            </div>
                            <div className="col-sm-10 col-md-10 col-lg-10">
                                {filteredShows.length === 0 ?
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                        <span>
                                            No Show Information Found
                                        </span>
                                    } />
                                    :
                                    <Table className="custom-ant-selection"
                                        rowKey={item => item.showcode}
                                        rowSelection={rowSelection}
                                        hideSelectionColumn={true}
                                        bordered
                                        dataSource={filteredShows}
                                        columns={columns}
                                        pagination={{
                                            defaultPageSize: 10,
                                            showSizeChanger: true,
                                            pageSizeOptions: ['10', '25', '50', '100'],
                                            showTotal: (total, range) => (
                                                <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                    Showing {range[0]}-{range[1]} of {total}
                                                </span>
                                            )
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: () => {
                                                    let selRows = [record.showcode];
                                                    setSelectedRowKeys([...selRows]);
                                                },
                                                onDoubleClick: (e) => {
                                                    loadShowDetails(e, record);
                                                }
                                            }
                                        }}
                                    />
                                }
                            </div>
                            <div className="col-sm-2 col-md-2 col-lg-2 text-left">
                                {state.shows.length > 0 &&
                                    <div>
                                        <table style={{ border: 'solid thin navy', padding: '10px' }}>
                                            <tbody>
                                                <tr><td colSpan="2" style={{ paddingLeft: '10px', paddingRight: '10px', fontWeight: 'bold' }}>Show Summary</td></tr>
                                                <tr><td style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }} colSpan="2">First Show: {state.firstShow}</td></tr>
                                                <tr><td style={{ paddingLeft: '10px' }}>Yes:</td><td className="text-right" style={{ paddingRight: '10px' }}>{state.totalYesShows}</td></tr>
                                                <tr><td style={{ paddingLeft: '10px' }}>Cancel:</td><td className="text-right" style={{ paddingRight: '10px' }}>{state.totalCancelShows}</td></tr>
                                                <tr><td style={{ paddingLeft: '10px' }}>No:</td><td className="text-right" style={{ paddingRight: '10px' }}>{state.totalNoShows}</td></tr>
                                                <tr><td style={{ paddingLeft: '10px' }}>Other:</td><td className="text-right" style={{ paddingRight: '10px' }}>{state.totalOtherShows}</td></tr>
                                                <tr><td colSpan="2" style={{ paddingLeft: '10px', paddingRight: '10px' }}><hr /></td></tr>
                                                <tr><td style={{ paddingLeft: '10px' }}><strong>Total:</strong></td><td className="text-right" style={{ paddingRight: '10px' }}>{state.totalShows}</td></tr>
                                            </tbody>
                                        </table>

                                        <div style={state.leadsList.length > 0 ? {} : { display: 'none' }}>
                                            <button className="btn btn-submit mt-3" onClick={handleExportLeads}>Download Collected Leads</button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </Spin>
            </div>

            {state.showShowDetailSliderVO &&
                <ShowDetailSliderVO selectedCustomerId={state.selectedCustomerId} selectedShowCode={state.selectedShowCode} selectedShowName={state.selectedShowName}
                    showPane={state.showShowDetailSliderVO} hidePane={() => setState({ ...state, showShowDetailSliderVO: false })} />
            }
        </div>
    );
}